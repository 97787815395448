
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import {Component, Prop, Watch} from 'vue-property-decorator';
import StatisticsData from "@/models/StatisticsData";
import {DateTime} from "luxon";

@Component
export default class LineChartComponent extends Vue {
  @Prop({required: true})
  public data!: StatisticsData;

  @Prop()
  public height!: number;

  @Prop({default: () => ''})
  public name!: string;

  @Prop({required: true})
  public color!: string;

  @Prop({default: []})
  public xScaling!: any[];

  @Prop({default: undefined})
  public title!: string;

  /**
   * current min x value of the zoomed area
   * @private
   */
  private xMin?: number;

  /**
   * current max x value of the zoomed area
   * @private
   */
  private xMax?: number;

  /**
   * initializes the chart
   * @private
   */
  public created() {
    Vue.component('apexchart', VueApexCharts);
  }

  @Watch('$vuetify.theme.dark')
  public updateTheme() {

    if (this.$vuetify.theme.dark) {
      this.chartOptions = {
        ...this.chartOptions, ...{
          tooltip: {
            theme: 'dark'
          }
        }
      };

    } else {

      this.chartOptions = {
        ...this.chartOptions, ...{
          tooltip: {
            theme: 'light'
          }
        }
      };
    }
  }

  @Watch('xScaling')
  private onXScalingChanged() {
    this.chartOptions = {
      ...this.chartOptions, ...{
        xaxis: {
          categories: this.categories
        }
      }
    };
  }

  @Watch('color')
  private onColorChanged() {
    this.chartOptions = {
      ...this.chartOptions, ...{
        colors: [this.color],
        markers: {
          strokeColor: [this.color]
        }
      }
    };
  }

  private mounted() {
    this.updateTheme();
  }

  /**
   * returns the series
   */
  get series(): any[] {
    const statisticData = this.data.values ?? [];
    return [{
      name: this.name,
      data: statisticData
    }];
  }

  /**
   * X-Axis scaling
   */
  get categories(): any[] {
    return this.xScaling;
  }

  private chartOptions: any = {
    chart: {
      events: {
        // creates event that is called when zoom is triggered, checks if the zoom difference of xmin and xmax is at
        // least 7500, this threshold is used to avoid that the chart is disappearing when the user is zooming in too
        // much, this way we cap the maximum zoom level, so that the chart will always be shown
        beforeZoom: (chartContext: any, {xaxis}: any) => {
          // gets the first and last date of the statistics and calculates difference between them
          const smallestDate = this.data.dates[this.data.dates.length -1];
          const highestData = this.data.dates[0];
          let dateDifference = (new Date(highestData)).valueOf() - new Date(smallestDate).valueOf();

          // calculates difference between the axis dates
          const diff = xaxis.max - xaxis.min;
          if (diff >= 30000000 && diff <= dateDifference) {
            this.xMin = xaxis.min;
            this.xMax = xaxis.max;
          }

          return {
            xaxis: {
              min: this.xMin,
              max: this.xMax,
            }
          };
        }
      },
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYAxis: true
      },
      foreColor: this.color,
      toolbar: {
        autoSelected: 'zoom',
        show: true,
        export: {
          csv: {
            filename: this.title,
          },
          svg: {
            filename: this.title,
          },
          png: {
            filename: this.title,
          }
        },
      },
      dropShadow: {
        enabled: true,
        top: 10,
        left: -10,
        blur: 5,
        color: this.$vuetify.theme.currentTheme['success']?.toString(),
        opacity: 0.1
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        type: 'radial',
        opacityFrom: 1,
        opacityTo: 1,
        colorStops: [
          {
            offset: 0,
            color: this.$vuetify.theme.currentTheme['primary']?.toString(),
            opacity: 1
          },
          {
            offset: 80,
            color: this.$vuetify.theme.currentTheme['success']?.toString(),
            opacity: 1
          },
        ],
      },
    },
    selection: {
      enabled: true,
      type: 'x',
      stroke: {
        dashArray: 3,
        opacity: 1
      },
    },
    stroke: {
      width: 6,
      curve: 'smooth',
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      marker: {
        show: true
      },
      x: {
        show: true,
        formatter: this.getDateByIndex
      },
      y: {
        show: true,
        formatter: this.getFormattedValue
      }
    },
    xaxis: {
      type: 'datetime',
      categories: this.categories,
      labels: {
        show: true,
        hideOverlappingLabels: true,
        rotateAlways: false,
        rotate: 0,
        offsetY: 0,
        style: {
          colors: this.$vuetify.theme.currentTheme['closeButton']?.toString(),
          fontSize: '12px',
          fontFamily: 'Signika-Regular'
        },
      },
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: false
      },
    },
    colors: [this.color],
    yaxis: {
      forceNiceScale: true,
      labels: {
        style: {
          colors: this.$vuetify.theme.currentTheme['closeButton']?.toString(),
          fontSize: '12px',
          fontFamily: 'Signika-Regular'
        },
      },
      axisBorder: {
        show: false
      }
    },
    markers: {
      size: 0,
      strokeColor: [this.color]
    },
    grid: {
      show: true,
      borderColor: this.$vuetify.theme.currentTheme['table-border'],
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: false
        }
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 15
      },
    },
  };

  /**
   * returns the date of selected index
   * @param index
   * @private
   */
  private getDateByIndex(index: number) {
    return DateTime.fromMillis(index).toFormat('DD');
  }

  /**
   * returns the formatted amount of ContainerType
   * @param value
   * @private
   */
  private getFormattedValue(value: number) {
    return this.$t('CONTAINER_OVERVIEW.STATISTICS.CONTAINER_AMOUNT', {value: value});
  }
}
